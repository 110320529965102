import React, {useContext, useState} from "react";
import "./Login.scss";
import { AuthContext } from "providers/AuthProvider";
import { SettingsContext } from "providers/SettingsProvider";
import TextField from '@mui/material/TextField';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import Paper from '@mui/material/Paper';

import { produce } from 'immer';
import axios from 'axios';
import {
  useNavigate,
  useLocation,
  Navigate,
} from "react-router-dom";

function Login() {
  const { Conf } = useContext(SettingsContext);
  const { auth,onLoggedIn } = useContext(AuthContext);
  const [ credentials,setCredentials ] = useState({username:'',password:''});
  const [ errors,setErrors ] = useState([]);
  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname || "/";

  const handleLogin=()=>{
    console.log(Conf.apiUrl+'login');
    axios.post(Conf.apiUrl+'login', credentials)
    .then(function (response) {
      if (response.data.loggedIn) {
        onLoggedIn(response.data);
        navigate(from !== '/login' ? from : '/', { replace: true });
      } else {
        setErrors(produce(errors,(draft)=>{
          draft.push(response.data.error)
        }))
      }
    })
    .catch(function (error) {
      setErrors(produce(errors,(draft)=>{
        draft.push(JSON.stringify(error));
      }))
    });
  }
  const handleChange=(key,value)=>{
    setCredentials(produce(credentials,(draft)=>{
      draft[key]=value;
    }));
  };
  const handleCloseSnack=()=>{
    setErrors(produce(errors,(draft)=>{
      draft.splice(0,1);
    }))
  }
  if (auth.token) {
    return <Navigate to="/"/>;
  }
  return (
    <div className="login">
    <Container maxWidth="sm">
      <Paper elevation={3} className="login-inner">
      <Grid container spacing={2} className="login">
        <Grid item xs={12}>
          <TextField
            label="Login"
            value={credentials.username}
            onChange={(e)=>handleChange('username',e.target.value)}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            value={credentials.password}
            onChange={(e)=>handleChange('password',e.target.value)}
            label="Password"
            type="password"
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <Button variant="contained" onClick={handleLogin}>Login</Button>
        </Grid>
      </Grid>
      </Paper>
      <Snackbar
        open={errors.length>0}
        onClose={handleCloseSnack}
        message={errors[0]}
        autoHideDuration={3000}
      />
    </Container>
    </div>
  )
}
export default Login;
