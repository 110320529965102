import React, {useContext,useMemo} from "react";
import "./Slides.scss";
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Slide from 'components/Slide';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import { ModelContext } from "providers/ModelProvider";
import { confirm } from 'components/Confirm'
import Accordion from 'components/Accordion';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';

function Slides({chapitreId}) {
  const { getCollection, newItem, save, remove } = useContext(ModelContext);
  const slides=useMemo(()=>getCollection('slide').filter((o)=>o.chapitreId===chapitreId).sort((a,b)=>a.sort-b.sort) || [],[getCollection,chapitreId]);
  const handleAddSlide=()=>{
    if (chapitreId) {
      let slide=newItem('slide');
      slide.chapitreId=chapitreId;
      save(slide);
    }
  }
  const handleDeleteSlide=(slide,e)=>{
    e.stopPropagation();
    confirm({
      titre: 'Supprimer la diapo ?',
      confirmation: 'Êtes vous sûr de vouloir supprimer la diapo ?'
    }).then((ok)=>{
      if (ok) remove(slide);
      else console.log('canceled');
    });
  }
  return <>
    <div className='spacer'></div>
    <Button variant="contained" startIcon={<AddIcon />} onClick={handleAddSlide}>
        Nouvelle diapo
      </Button>
      <div className='spacer'></div>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Accordion
          accordionId={'chap-'+chapitreId}
          list={slides.map((slide)=>{return {
            id:slide._id,
            summary:<Typography>{(slide.titre || 'Sans titre') + ' '}
            <IconButton
              size="small"
              color="inherit"
              onClick={(e)=>handleDeleteSlide(slide,e)}
            >
              <DeleteIcon />
            </IconButton>
            </Typography>,
            details:<Slide id={slide._id}/>,
          }})} items={slides}/>
        </Grid>
      </Grid>
  </>
}

export default Slides;
