import React from 'react';
import { confirmable } from 'react-confirm';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

const YourDialog = ({show, proceed, confirmation, titre, options}) => (
  <Dialog
    open={show}
    onClose={() => proceed(false)}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
  >
    <DialogTitle id="alert-dialog-title">
      {titre}
    </DialogTitle>
    <DialogContent>
      <DialogContentText id="alert-dialog-description">
      {confirmation}
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button onClick={() => proceed(false)}>ANNULER</Button>
      <Button onClick={() => proceed(true)} autoFocus>OK</Button>
    </DialogActions>
  </Dialog>
)
// confirmable HOC pass props `show`, `dismiss`, `cancel` and `proceed` to your component.
export default confirmable(YourDialog);
