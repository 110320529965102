import React, { useState, createContext, useEffect, useCallback } from "react";
import { produce } from 'immer';
import axios from 'axios';
import {
  useNavigate,
} from "react-router-dom";
import Conf from 'Conf';

export const AuthContext = createContext({});
const token=localStorage.getItem('presentations_token');
const username=localStorage.getItem('presentations_username');
let renewInterval=null;

const AuthProvider = ({children})=>{
  const [ auth,setAuth ] = useState({token,username});
  const [ checked,setChecked ] = useState(false);
  const navigate = useNavigate();
  const onLoggedIn=useCallback(({token,username})=>{
    setAuth(produce(auth,(draft)=>{
      draft.token=token;
      draft.username=username;
    }));
    localStorage.setItem('presentations_token', token);
    localStorage.setItem('presentations_username', username);
  },[auth,setAuth]);
  const onLoggedOut=useCallback(()=>{
    setAuth(produce(auth,(draft)=>{
      draft.token=null;
      draft.username=null;
    }));
    localStorage.removeItem('presentations_token');
    localStorage.removeItem('presentations_username');
  },[auth,setAuth]);
  const renew=useCallback(()=>{
    axios.post(Conf.apiUrl+'renew', {}, { headers: { Authorization: 'Bearer '+auth.token} })
    .then(function (response) {
      if (response.data.loggedIn) {
        onLoggedIn(response.data);
      } else {
        onLoggedOut();
        navigate('/login', { replace: true });
      }
    })
    .catch(function (error) {
      onLoggedOut();
      navigate('/login', { replace: true });
    });
  },[auth.token,onLoggedIn,onLoggedOut,navigate]);
  useEffect(()=>{
    if(auth.token && !checked) {
      renew();
      setChecked(true);
      if (renewInterval) clearInterval(renewInterval);
      renewInterval=setInterval(renew,Conf.renewInterval);
    }
    if (!auth.token && renewInterval) clearInterval(renewInterval);
  },[auth.token,renew,checked,setChecked])
  return (
        <AuthContext.Provider value={{auth,setAuth,onLoggedIn,onLoggedOut}}>
            {children}
        </AuthContext.Provider>
    );
}
export default AuthProvider;
