import React, { useContext, useState, useMemo, useEffect } from "react";
import "./Select.scss";
import { ModelContext } from "providers/ModelProvider";
import InputAdornment from '@mui/material/InputAdornment';
import CircularProgress from '@mui/material/CircularProgress';
import CheckIcon from '@mui/icons-material/Check';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import SelectMui from '@mui/material/Select';
import * as _ from 'lodash';

function Select({docId,path,name='',options,multiple=false,validate=()=>true}) {
  const { savePath, getDocPath } = useContext(ModelContext);
  const dbValue=getDocPath(docId,path);
  const [ value,setValue ] = useState(dbValue);
  const handleChange=(v)=>{
    if (validate(v)) {
      setValue(v);
      save(v);
    }
  };
  useEffect(()=>{
    setValue(dbValue)
  },[dbValue]);
  const save=useMemo(()=>_.debounce((v)=>{
    savePath(docId,path,v);
  },500),[docId,path,savePath]);
  const saved= _.isEqual(dbValue,value);
  return (
    <div className="input-select">
      <FormControl fullWidth>
        <InputLabel>{name}</InputLabel>
        <SelectMui
        value={value===null ? '' : value}
        label={name}
        multiple={multiple}
        onChange={(e)=>handleChange(e.target.value)}
        endAdornment={<InputAdornment className='is-saved' position="end">{saved ? <CheckIcon/> : <CircularProgress size={20}/>}</InputAdornment>}
        >
          {options.map((option)=><MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>)}
        </SelectMui>
      </FormControl>
    </div>
  )
}

export default Select;
