import React, { useContext, useState, useEffect } from "react";
import "./Text.scss";
import { ModelContext } from "providers/ModelProvider";
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import CircularProgress from '@mui/material/CircularProgress';
import CheckIcon from '@mui/icons-material/Check';

let saveTimer=null;

function Text({docId,path,name='',validate=()=>true}) {
  const { savePath, getDocPath } = useContext(ModelContext);
  const dbValue=getDocPath(docId,path);
  const [ value,setValue ] = useState(dbValue);
  const handleChange=(v)=>{
    setValue(v);
    if(validate(v)) {
      clearTimeout(saveTimer);
      saveTimer=setTimeout(()=>{
        savePath(docId,path,v);
      },1000);
    }
  };
  useEffect(()=>{
    setValue(dbValue);
  },[dbValue,setValue]);
  const saved= dbValue===value;
  return (
    <div className={"input-text"+(validate(value) ? '' : ' invalid')}>
      <TextField
      fullWidth
      label={name}
      variant="outlined"
      value={value}
      onChange={(e)=>handleChange(e.target.value)}
      InputProps={{
        endAdornment: validate(value) ? (<InputAdornment position="end">{saved ? <CheckIcon/> : <CircularProgress size={20}/>}</InputAdornment>) : null,
      }}/>
    </div>
  )
}

export default Text;
