import React, {useContext} from "react";
import "./Slide.scss";
import { ModelContext } from "providers/ModelProvider";
import Text from 'components/form/Text';
import TextLong from 'components/form/TextLong';
import Upload from 'components/form/Upload';
import Grid from '@mui/material/Grid';

function Slide({ id }) {
  const { getDoc } = useContext(ModelContext);
  const doc=getDoc(id);
  return doc && doc.type==='slide' ? <>
    <Grid container spacing={2}>
      <Grid item xs={12} md={6}>
        <Upload name='Media' docId={id} path={['media']}/>
      </Grid>
      <Grid item xs={12} md={6}>
        <Text name='Titre' docId={id} path={['titre']}/>
        <TextLong name='Texte' docId={id} path={['texte']}/>
      </Grid>
    </Grid>
  </> : '';
}

export default Slide;
