import React, {useContext} from "react";
import "./Presentations.scss";
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import { ModelContext } from "providers/ModelProvider";
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import { confirm } from 'components/Confirm'
import {
  Link,
} from "react-router-dom";

function Presentations() {
  const { getCollection, newItem, save, remove } = useContext(ModelContext);
  const presentations=getCollection('presentation').sort((a,b)=>a.sort-b.sort);
  console.log(presentations);
  const handleAddPresentation=()=>{
    let presentation=newItem('presentation');
    save(presentation);
  }
  const handleDeletePresentation=(presentation)=>{
    confirm({
      titre: 'Supprimer la présentation ?',
      confirmation: 'Êtes vous sûr de vouloir supprimer la présentation ?'
    }).then((ok)=>{
      if (ok) remove(presentation);
      else console.log('canceled');
    });
  }
  return <>
    <Container maxWidth="lg">
      <Typography variant="h1" component="h1">
        Présentations
      </Typography>
      <Button variant="contained" startIcon={<AddIcon />} onClick={handleAddPresentation}>
        Nouvelle
      </Button>
      <div className='spacer'></div>
      <Grid container spacing={2}>
        {presentations.map((presentation)=><Grid key={presentation._id} item xs={12} md={4}>
          <Card sx={{ minWidth: 275 }}>
            <CardContent>
              <Typography variant="h4" component="h4" gutterBottom>
                {presentation.titre || 'Sans titre'}
              </Typography>
            </CardContent>
            <CardActions>
              <Link to={'/presentation/'+presentation._id}><Button size="small">Modifier</Button></Link>
              <Button size="small" onClick={()=>handleDeletePresentation(presentation)}>Supprimer</Button>
            </CardActions>
          </Card>
        </Grid>)}
      </Grid>
    </Container>
  </>
}

export default Presentations;
