import React, {useContext} from "react";
import "./Presentation.scss";
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import { ModelContext } from "providers/ModelProvider";
import { useParams } from "react-router-dom";
import Text from 'components/form/Text';
import Chapitres from 'components/Chapitres';

function Presentation() {
  const { id } = useParams();
  const { getDoc } = useContext(ModelContext);
  const doc=getDoc(id);
  return doc && doc.type==='presentation' ? <>
    <Container maxWidth="lg">
      <Typography variant="h1" component="h2">
        Présentation
      </Typography>
      <Typography variant="h2" component="h2">
        {doc.titre || 'Sans titre'}
      </Typography>
      <div className='spacer'></div>
      <Text name='Titre' docId={id} path={['titre']}/>
      <Chapitres presentationId={id}/>
    </Container>
  </> : '';
}

export default Presentation;
