import React, { useState, createContext, useEffect, useContext, useCallback } from "react";
import Conf from 'Conf';
import { AuthContext } from "providers/AuthProvider";
import PouchDB from 'pouchdb';
import queue from 'queue';
import * as _ from 'lodash';
import { produce } from 'immer';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';

let localDB=new PouchDB('presentation');
let remoteDB = new PouchDB(Conf.dbUrl,{
  fetch:(url, opts)=>{
    opts.credentials='omit';
    return PouchDB.fetch(url, opts);
  }
});
const saveQ=new queue({concurrency:1,autostart:true});
let sync=null;
const parseCustomPath=(customPath,doc,createItem=false)=>{
  //console.log(customPath,doc);
  let p=[...customPath];
  let path=[];
  while (p.length>0) {
    let current=p[0];
    let el=path.length>0 ? _.get(doc,path) : doc;
    if (el) {
      if (Array.isArray(current)) {
        let id=current[0];
        let idKey=current[1] || '_id';
        if (Array.isArray(el)) {
          let idx=el.findIndex((o)=>o[idKey]===id);
          if (idx!==-1) path.push(idx);
          else {
            if (createItem) {
              path.push(el.length);
              el.push({[idKey]:id});
            } else {
              console.log('not found');
              return false;
            }
          }
        } else {
          console.log('not found');
        }
      } else {
        path.push(current)
      }
    } else {
      console.log('not found');
    }
    p.splice(0,1);
  }
  //console.log('parseCustomPath',customPath,path);
  return path;
};
const removePath=(doc,path)=>{
  if (path.length<=1) {
    _.unset(doc,path);
  } else {
    let item=_.get(doc,path);
    let parent=_.get(doc,path.slice(0,-1));
    if (Array.isArray(parent)) {
      let idx=parent.indexOf(item);
      parent.splice(idx,1);
    } else {
      _.unset(doc,path);
    }
  }
};
const get=(o,path)=>{
  return _.get(o,parseCustomPath(path,o));
}
const check=(doc,modele)=>{
  let touched=false;
  if(!doc.sort) {
    doc.sort=Date.now();
    touched=true;
  }
  if(doc.type==='interlocuteur') {
    if (!doc.photo || !Array.isArray(doc.photo)) {
      doc.photo=[];
      touched=true;
    }
  }
  if(doc.type==='conversation') {
    if (!doc.interlocuteurs || !Array.isArray(doc.interlocuteurs)) {
      doc.interlocuteurs=[];
      touched=true;
    }
    let oldInterlocuteurs=[];
    let interlocuteurs=modele.filter((o)=>o.doc.type==='interlocuteur' && o.doc.portraitId===doc.portraitId).map((o)=>o.doc);
    doc.interlocuteurs.forEach((interlocuteurId, i) => {
      const idx=interlocuteurs.findIndex((o)=>o._id===interlocuteurId);
      if (idx===-1) oldInterlocuteurs.push(interlocuteurId);
    });
    oldInterlocuteurs.forEach((id, i) => {
      const idx=doc.interlocuteurs.findIndex((o)=>o._id===id);
      doc.interlocuteurs.splice(idx,1);
      touched=true;
    });

    if (!doc.date && doc.date!=='') {
      doc.date='';
      touched=true;
    }
  }
  if(doc.type==='message') {
    if (!doc.from) {
      const conversation=modele.find((o)=>o.doc.type==='conversation' && o.doc._id===doc.conversationId);
      if (conversation) doc.from=conversation.doc.portraitId;
      touched=true;
    }
  }
  return touched;
}
const collectionCache={};
export const ModelContext = createContext({});

const ModelProvider = ({children})=>{
  const { auth } = useContext(AuthContext);
  const [ modele,setModele ] = useState([]);
  const [ published,setPublished ] = useState(true);
  const updateModele=useCallback(()=>{
    localDB.allDocs({include_docs:true},(err,d)=>{
      let changes=[]
      d.rows.forEach((docFull)=>{
        let doc=docFull.doc;
        if (check(doc,d.rows)) changes.push(doc);
      });
      setModele(d.rows);
      if (changes.length>0) {
        console.log('changes !',changes);
        localDB.bulkDocs(changes);
      }
    });
  },[setModele]);
  const onChange=useCallback((info)=>{
    console.log('change');
    updateModele();
  },[updateModele]);
  const onPaused=useCallback(()=>{
    console.log('paused');
  },[]);
  const onActive=useCallback(()=>{
    console.log('active');
  },[]);
  const onError=useCallback((err)=>{
    console.log('error',err);
  },[]);
  useEffect(()=>{
    console.log('rebuild');
    let q= new queue({concurrency:1});
    if (sync) sync.cancel();
    q.push((cb)=>{
      remoteDB.close().then(()=>{
        console.log('closed');
        cb();
      }).catch((err)=>{
        console.log('already closed');
        cb();
      });
    });
    if(auth.token) {
      q.push((cb)=>{
        remoteDB=new PouchDB(Conf.dbUrl,{
          fetch:(url, opts)=>{
            opts.credentials='omit';
            opts.headers.set('Authorization', 'Bearer ' + auth.token);
            return PouchDB.fetch(url, opts);
          }
        });
        sync=localDB.sync(remoteDB, {
          live: true,
          retry: true
        }).on('change', onChange).on('paused', onPaused).on('active', onActive).on('error', onError);
        console.log(sync);
        cb();
      });
    }
    q.on('end',()=>{
      console.log('rebuilt !');
    });
    q.start();
  }
  ,[auth,onChange,onPaused,onActive,onError]);
  useEffect(updateModele,[setModele,updateModele]);
  // useEffect(()=>{
  //   const doc={
  //     a:{
  //       files:[
  //         {_id:'titi',color:'red',items:[
  //           {url:'cool',color:'blue1'},
  //           {url:'super',color:'black1'},
  //         ]},
  //         {_id:'toto',color:'green',items:[
  //           {url:'cool',color:'blue2'},
  //           {url:'super',color:'black2'},
  //         ]},
  //       ]
  //     }
  //   };
  //   let path=parseCustomPath(['a','files',['toto'],'items'],doc,true);
  //   removePath(doc,path);
  //   console.log(path,_.get(doc,path),doc);
  // },[]);
  const doSave=useCallback((doc,cb=()=>null)=>{
    localDB.put(doc, (err, result)=>{
      if (!err) {
        console.log('Success');
        updateModele();
        cb();
      } else {
        console.log(err);
        cb();
      }
    });
  },[updateModele]);
  const doSaveBulk=useCallback((docs,cb=()=>null)=>{
    localDB.bulkDocs(docs, (err, result)=>{
      if (!err) {
        console.log('Success');
        updateModele();
        cb();
      } else {
        console.log(err);
        cb();
      }
    });
  },[updateModele]);
  const doRemove=useCallback((doc,cb=()=>null)=>{
    localDB.remove(doc, (err, result)=>{
      if (!err) {
        console.log('Success');
        updateModele();
        cb();
      } else {
        console.log(err);
        cb();
      }
    });
  },[updateModele]);
  const savePath=useCallback((id,customPath,value)=>{
    saveQ.push((cb)=>{
      console.log('savePath',id,customPath,value);
      localDB.get(id).then(function (dbDoc) {
        const saveDoc=produce(dbDoc,(draft)=>{
          if (value===null) removePath(draft,parseCustomPath(customPath,draft,true));
          else _.set(draft,parseCustomPath(customPath,draft,true),value);
        });
        console.log('doSave',saveDoc);
        doSave(saveDoc,cb);
      }).catch(function (err) {
        console.log(err);
        cb();
      });
    });
  },[doSave]);
  const savePathCollectionItem=(id,path,item,itemId,idKey='id')=>{
    saveQ.push((cb)=>{
      console.log('savePathCollectionItem',id,path,item,itemId,idKey);
      localDB.get(id).then(function (dbDoc) {
        const saveDoc=produce(dbDoc,(draft)=>{
          let collection=_.get(draft,path);
          if (Array.isArray(collection)) {
            let idx=collection.findIndex((o)=>o[idKey]===itemId);
            if (item) {
              if (idx!==1) collection[idx]=item;
              else collection.push(item);
            } else {
              if (idx!==-1) collection.splice(idx,1);
            }
          }
        });
        doSave(saveDoc,cb);
      }).catch(function (err) {
        console.log(err);
        cb();
      });
    });
  }
  const save=useCallback((doc)=>{
    saveQ.push((cb)=>{
      console.log('save',doc);
      localDB.get(doc._id).then(function (dbDoc) {
        const saveDoc={...doc,_rev:dbDoc._rev};
        doSave(saveDoc,cb);
      }).catch(function (err) {
        if(err.reason==="missing") {
          doc.sort=Date.now();
          doSave(doc,cb);
        } else {
          console.log(err);
          cb();
        }
      });
    });
  },[doSave]);
  const remove=useCallback((doc)=>{
    saveQ.push((cb)=>{
      console.log('remove',doc);
      localDB.get(doc._id).then(function (dbDoc) {
        const saveDoc={...doc,_rev:dbDoc._rev};
        doRemove(saveDoc,cb);
      }).catch(function (err) {
        console.log(err);
        cb();
      });
    });
  },[doRemove]);
  const getDoc=useCallback((docId)=>{
    return get(modele,[[docId,'id'],'doc']);
  },[modele]);
  const getCollection=useCallback((type)=>{
    const fresh=modele.filter((o)=>o.doc.type===type).map((o)=>o.doc).sort((a,b)=>a.sort-b.sort);
    if (collectionCache[type] && _.isEqual(collectionCache[type],fresh)) {
      return collectionCache[type];
    }
    collectionCache[type]=fresh;
    return fresh;
  },[modele]);
  const getDocPath=(docId,path)=>{
    const doc=getDoc(docId);
    return get(doc,path);
  }
  const getAudioHooks=useCallback(()=>{
    const res=[];
    const themes=getCollection('theme').sort((a,b)=>a.sort-b.sort);
    res.push({
      label:'Themes',
      value:'themes',
    });
    res.push({
      label:'Theme',
      value:'theme',
    });
    themes.forEach((theme, i) => {
      res.push({
        label:'Theme : '+theme.titre,
        value:'theme/'+theme._id,
      })
    });
    const lettres=getCollection('lettre').sort((a,b)=>a.sort-b.sort);
    res.push({
      label:'Lettre',
      value:'lettre',
    });
    lettres.forEach((lettre, i) => {
      res.push({
        label:'Lettre : '+lettre.from+' > '+lettre.to,
        value:'lettre/'+lettre._id,
      });
      res.push({
        label:'Lettre Play : '+lettre.from+' > '+lettre.to,
        value:'lettrePlay/'+lettre._id,
      })
      res.push({
        label:'Lettre Pause : '+lettre.from+' > '+lettre.to,
        value:'lettrePause/'+lettre._id,
      })
      res.push({
        label:'Lettre Stop : '+lettre.from+' > '+lettre.to,
        value:'lettreStop/'+lettre._id,
      })
    });
    const portraits=getCollection('portrait').sort((a,b)=>a.sort-b.sort);
    res.push({
      label:'Portrait',
      value:'portrait',
    });
    res.push({
      label:'Conversation',
      value:'conversation',
    });
    res.push({
      label:'Message',
      value:'message',
    });
    portraits.forEach((portrait) => {
      const conversations=getCollection('conversation').filter((o)=>o.portraitId===portrait._id).sort((a,b)=>a.sort-b.sort);
      res.push({
        label:'Portrait : '+portrait.nom,
        value:'portrait/'+portrait._id,
      })
      res.push({
        label:'Portrait : '+portrait.nom+' > conversation',
        value:'portrait/'+portrait._id+'/conversation',
      })
      res.push({
        label:'Portrait : '+portrait.nom+' > message',
        value:'portrait/'+portrait._id+'/message',
      })
      conversations.forEach((conversation) => {
        const interlocuteurs=[...getCollection('interlocuteur').filter((o)=>conversation.interlocuteurs.indexOf(o._id)!==-1),portrait].map((o)=>{return {label:o.nom || 'Sans nom',value:o._id}});
        const messages=getCollection('message').filter((o)=>o.conversationId===conversation._id).sort((a,b)=>a.sort-b.sort);
        res.push({
          label:'Conversation : '+portrait.nom+' '+conversation.date,
          value:'conversation/'+conversation._id,
        })
        res.push({
          label:'Conversation : '+portrait.nom+' '+conversation.date+' > message',
          value:'conversation/'+conversation._id+'/message',
        })
        messages.forEach((message) => {
          const from=interlocuteurs.find((o)=>o.value===message.from)
          res.push({
            label:'Message : '+portrait.nom+' '+conversation.date+' '+message.heure+' ('+from.label+')',
            value:'message/'+message._id,
          })
        });
      });
    });
    return res;
  },[getCollection]);
  const publish=()=>{
    setPublished(false);
    axios.post(Conf.apiUrl+'publish', {}, { headers: { Authorization: 'Bearer '+auth.token} })
    .then(function (response) {
      console.log(response.data);
      setTimeout(()=>setPublished(true),1000);
    })
    .catch(function (error) {
      console.log(error)
    });
  }
  const newItem=(type)=>{
    let id=uuidv4();
    return {...Conf.skels[type],_id:id};
  }
  return (
        <ModelContext.Provider value={{getAudioHooks,modele,publish,published,get,getDoc,getDocPath,getCollection,newItem,setModele,save,remove,savePath,savePathCollectionItem,doSaveBulk}}>
            {children}
        </ModelContext.Provider>
    );
}
export default ModelProvider;
