import React, {useCallback, useContext, useState } from "react";
import "./Upload.scss";
import {useDropzone} from 'react-dropzone'
import * as tus from "tus-js-client";
import { SettingsContext } from "providers/SettingsProvider";
import { ModelContext } from "providers/ModelProvider";
import { produce } from 'immer';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import Snackbar from '@mui/material/Snackbar';
import Conf from 'Conf';
import Typography from '@mui/material/Typography';
import ReactAudioPlayer from 'react-audio-player';

function Upload({name='',docId,path,maxFiles=1,type='image',volume=1,maxWidth='100%',shadow=true}) {
  const { savePath, getDocPath } = useContext(ModelContext);
  const { uploaders, addUploaders } = useContext(SettingsContext);
  const [ errors,setErrors ] = useState([]);
  const v=getDocPath(docId,path);
  const value=Array.isArray(v) ? v : [];
  //console.log(path,value);
  const addFile=useCallback((f)=>{
    console.log('addFile',f);
    savePath(docId,[...path,[f.url,'url']],f);
  },[savePath,docId,path]);
  const handleDelete=useCallback((f)=>{
    savePath(docId,[...path,[f.url,'url']],null);
  },[savePath,docId,path]);
  const onDrop = useCallback((acceptedFiles) => {
    let newUploaders=[];
    let myUploaders=uploaders.filter((o)=>o.path===path && o.docId===docId);
    acceptedFiles.forEach((file, i) => {
      // Create a new tus upload
      if (value.length+myUploaders.length+i<maxFiles) {
        let upload = new tus.Upload(file, {
          endpoint: Conf.uploadUrl,
          onError: (error)=>{
            console.log("Failed because: " + error);
          },
          retryDelays: [0, 3000, 5000, 10000, 20000],
          metadata: {
            filename: file.name,
            filetype: file.type
          },
        });
        newUploaders.push(upload);
        // Check if there are any previous uploads to continue.
      } else {
        console.log('Trop de fichiers, nombre max autorisé : '+maxFiles);
        setErrors(errors=>[...errors,'Trop de fichiers, nombre max autorisé : '+maxFiles]);
      }
    });
    addUploaders(newUploaders,docId,path,addFile);
  },[addUploaders, addFile, maxFiles,docId,path,value.length,uploaders]);
  const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop})
  const handleCloseSnack=()=>{
    setErrors(produce(errors,(draft)=>{
      draft.splice(0,1);
    }))
  }
  return (
    <div className="input-upload">
      {name && <Typography variant="h5" component="h5" className='input-upload-name'>
        {name}
      </Typography>}
      {value.length<maxFiles && <div className={'upload-drop-zone'+(isDragActive ? ' active' : '')} {...getRootProps()}>
        <input {...getInputProps()} />
        <p>Déposez les fichiers ici, ou cliquez pour selectionner des fichiers</p>
      </div>}
      {value.slice(0).reverse().map((file,i)=><div key={i}>
      {file && type==='image' ? <>
        <div className="preview-container" style={{maxWidth}}>
          <div className="preview">
            {file.type.startsWith('image/') && <img src={Conf.filesUrl+file.url+"-600"} alt="" className={shadow ? 'shadow' : null}/>}
            {file.type.startsWith('video/') && <video controls src={Conf.filesUrl+file.url} alt="" className={shadow ? 'shadow' : null}/>}
          </div>
        </div>
        <div className="name">
        <a href={Conf.filesUrl+file.url} target="_blanc">{file.name}</a> <IconButton
          size="small"
          color="inherit"
          onClick={()=>handleDelete(file)}
        >
          <DeleteIcon />
        </IconButton>
        </div>
      </>:null}
      {file && type==='son' ? <>
          <ReactAudioPlayer className="son-preview" src={Conf.filesUrl+file.url} volume={volume} controls/>
          <div className="name">
          <a href={Conf.filesUrl+file.url} target="_blanc">{file.name}</a> <IconButton
            size="small"
            color="inherit"
            onClick={()=>handleDelete(file)}
          >
            <DeleteIcon />
          </IconButton>
          </div>
        </>:null}
        </div>
      )}
      <Snackbar
        open={errors.length>0}
        onClose={handleCloseSnack}
        message={errors[0]}
        autoHideDuration={5000}
      />
    </div>
  )
}

export default Upload;
