import React, {useContext} from "react";
import "./Chapitres.scss";
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Chapitre from 'components/Chapitre';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import { ModelContext } from "providers/ModelProvider";
import { confirm } from 'components/Confirm'
import Accordion from 'components/Accordion';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';

function Chapitres({presentationId}) {
  const { getCollection, newItem, save, remove } = useContext(ModelContext);
  const chapitres=getCollection('chapitre').filter((o)=>o.presentationId===presentationId).sort((a,b)=>a.sort-b.sort);
  const handleAddChapitre=()=>{
    if (presentationId) {
      let chapitre=newItem('chapitre');
      chapitre.presentationId=presentationId;
      save(chapitre);
    }
  }
  const handleDeleteChapitre=(chapitre,e)=>{
    e.stopPropagation();
    confirm({
      titre: 'Supprimer le chapitre ?',
      confirmation: 'Êtes vous sûr de vouloir supprimer le chapitre ?'
    }).then((ok)=>{
      if (ok) remove(chapitre);
      else console.log('canceled');
    });
  }
  return <>
    <div className='spacer'></div>
    <Button variant="contained" startIcon={<AddIcon />} onClick={handleAddChapitre}>
        Nouveau chapitre
      </Button>
      <div className='spacer'></div>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Accordion
          accordionId={'pres-'+presentationId}
          list={chapitres.map((chapitre)=>{return {
          id:chapitre._id,
          summary:<Typography>{(chapitre.titre || 'Sans titre') + ' '}
            <IconButton
              size="small"
              color="inherit"
              onClick={(e)=>handleDeleteChapitre(chapitre,e)}
            >
              <DeleteIcon />
            </IconButton>
          </Typography>,
          details:<Chapitre id={chapitre._id}/>,
        }})}
        items={chapitres}/>
        </Grid>
      </Grid>
  </>
}

export default Chapitres;
