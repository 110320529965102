import React, {useContext,useState,useEffect} from "react";
import "./Accordion.scss";
import { ModelContext } from "providers/ModelProvider";
import { SettingsContext } from "providers/SettingsProvider";
import AccordionMui from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { produce } from 'immer';

function Accordion({ accordionId, list, items }) {
  const { doSaveBulk } = useContext(ModelContext);
  const { appState, setAppState } = useContext(SettingsContext);
  const [ cacheList,setCacheList ] = useState([]);
  useEffect(()=>{
    setCacheList([...list]);
  },[list,setCacheList]);
  const handleExpandedChange=(id) => (event, isExpanded) => {
    setAppState((state)=>{return { ...state, accordions: { ...state.accordions, [accordionId]:isExpanded ? id : false}  } });
  };
  const expanded=appState.accordions[accordionId];
  const getItemStyle = (draggableStyle, isDragging) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: 'none',
    // styles we need to apply on draggables
    ...draggableStyle
  });

  const reorder =  ({source, destination}) => {
    let changed=false;
    const sortedSlides = produce(items,(draft)=>{
      const sorts=draft.map((o)=>o.sort);
      const [removed] = draft.splice(source.index, 1);
      draft.splice(destination.index, 0, removed);
      draft.forEach((item, i) => {
        if (item.sort!==sorts[i]) {
          changed=true;
          item.sort=sorts[i];
        }
      });
    });
    if (changed) {
      setCacheList(produce(cacheList,(draft)=>{
        const [removed] = draft.splice(source.index, 1);
        draft.splice(destination.index, 0, removed);
      }));
      doSaveBulk(sortedSlides);
    }
  };
  const onDragEnd=(result) => {
    // dropped outside the list
    if(!result.destination) {
       return;
    }
    reorder(result);
  }
  return <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable">
        {(provided, snapshot) => (
          <div
            ref={provided.innerRef}
            {...provided.droppableProps}
            className="accordion-wrapper"
          >
            {cacheList.map(({id,summary,details},index)=>
              <Draggable
                key={id}
                draggableId={id}
                index={index}
              >
                {(provided, snapshot) => (
                  <div className={"accordion-inner"+(expanded === id ? ' expanded' : '')+(snapshot.isDragging ? ' dragging' : '')}>
                    <div
                      ref={provided.innerRef}
                      {...provided.dragHandleProps}
                      {...provided.draggableProps}
                      style={getItemStyle(
                        provided.draggableProps.style,
                        snapshot.isDragging
                      )}
                    >
                    <AccordionMui
                      square
                      key={id}
                      expanded={expanded === id}
                      onChange={handleExpandedChange(id)}>
                      <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      >
                        {summary}
                      </AccordionSummary>
                      <AccordionDetails>
                        {details}
                      </AccordionDetails>
                    </AccordionMui>
                    </div>
                    {provided.placeholder}
                  </div>
                 )}
              </Draggable>
          )}
          {provided.placeholder}
        </div>
      )}
      </Droppable>
    </DragDropContext>;
}

export default Accordion;
