const Conf={
  dbUrl:process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL+"db/model/" : "https://presentations-api.gmea.net/db/model",
  apiUrl:process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : "https://presentations-api.gmea.net/",
  uploadUrl:process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL+"upload/" : "https://presentations-api.gmea.net/upload/",
  filesUrl:process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL+"files/" : "https://presentations-api.gmea.net/files/",
  renewInterval:5*60*1000,
  skels:{
    presentation:{
      type:'presentation',
      titre:''
    },
    chapitre:{
      type:'chapitre',
      titre:'',
      image:[],
      couleur:'#FFF',
      slides:[],
      presentationId:null,
    },
    slide:{
      type:'slide',
      titre:'',
      texte:'',
      media:[],
      chapitreId:null,
    },
  }
}
export default Conf;
