import React, {useContext} from "react";
import "./Chapitre.scss";
import { ModelContext } from "providers/ModelProvider";
import Text from 'components/form/Text';
import Select from 'components/form/Select';
import Slides from 'components/Slides';
import Upload from 'components/form/Upload';
import Grid from '@mui/material/Grid';

const palette=[
  '#6AE165',
  '#ED8CE2',
  '#7CF3F3',
  '#FFF500',
  '#FFDDA8',
  '#b9bbbe',
  '#C2FFBB',
  '#ff3f00',
  '#c3ffff',
  '#c3ffe1',
  '#c2ffb5',
  '#d3ff88',
  '#ecff87',
  '#ffff78',
  '#f9e893',
  '#f2cd99',
  '#f2cdb5',
  '#ecb1b2',
  '#e89bd3',
  '#e798fb',
  '#ca95fb',
  '#bfa9fb',
  '#aab2fc',
  '#abc9fc',
  '#c1e3fe'
];
function isHex(str) {
  return /^(#[A-F0-9]{3}|#[A-F0-9]{6})$/i.test(str);
}
function Chapitre({ id }) {
  const { getDoc } = useContext(ModelContext);
  const doc=getDoc(id);
  let couleurs=[...palette];
  if (doc.couleur && couleurs.indexOf(doc.couleur)===-1) couleurs=[doc.couleur,...palette];
  return doc && doc.type==='chapitre' ? <>
    <Grid container spacing={2}>
      <Grid item xs={12} md={6}>
        <Upload name='Image' docId={id} path={['image']}/>
      </Grid>
      <Grid item xs={12} md={6}>
        <Text name='Titre' docId={id} path={['titre']}/>
        <Text name='Couleur' docId={id} path={['couleur']} validate={isHex}/>
        <Select name='Couleur' docId={id} path={['couleur']} options={couleurs.map((c)=>{return {label:<div className="couleur-item" style={{backgroundColor:c}}></div>, value:c}})}/>
      </Grid>
    </Grid>
    <Slides chapitreId={id}/>
  </> : '';
}

export default Chapitre;
