import React, {useContext} from "react";
import './App.css';

import Login from 'components/Login';
import Presentations from 'components/Presentations';
import Presentation from 'components/Presentation';
import Topbar from 'components/Topbar';
import Uploads from 'components/Uploads';

import { AuthContext } from "providers/AuthProvider";
import {
  Routes,
  Route,
  useLocation,
  Navigate
} from "react-router-dom";

function RequireAuth({ children }) {
  const { auth } = useContext(AuthContext);
  const location = useLocation();
  if (!auth.token) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to="/login" state={{ from: location }} />;
  }
  return children;
}

function App() {
  const { auth } = useContext(AuthContext);
  return (
    <>
      {auth.token && <Topbar/>}
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/presentations" element={<RequireAuth><Presentations /></RequireAuth>} />
        <Route path="/presentation/:id" element={<RequireAuth><Presentation /></RequireAuth>} />
        <Route path="*" element={<RequireAuth><Presentations /></RequireAuth>} />
        </Routes>
      {auth.token && <Uploads/>}
    </>
  );
}

export default App;
