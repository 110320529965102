import React, { useContext, useState, useEffect } from "react";
import "./TextLong.scss";
import { ModelContext } from "providers/ModelProvider";
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import CircularProgress from '@mui/material/CircularProgress';
import CheckIcon from '@mui/icons-material/Check';

let parseTimer=null;
let saveTimer=null;

function TextLong({docId,path,name='',parse=(v)=>null}) {
  const { savePath, getDocPath } = useContext(ModelContext);
  const dbValue=getDocPath(docId,path);
  const [ value,setValue ] = useState(dbValue);
  const handleChange=(v)=>{
    setValue(v);
    clearTimeout(parseTimer);
    parseTimer=setTimeout(()=>{
      parse(v);
    },3000);
    clearTimeout(saveTimer);
    saveTimer=setTimeout(()=>{
      savePath(docId,path,v);
    },1000);
  };
  useEffect(()=>{
    setValue(dbValue);
  },[dbValue,setValue]);
  const saved= dbValue===value;
  return (
    <div className="input-text-long">
      <TextField
      multiline
      minRows={3}
      fullWidth
      label={name}
      variant="outlined"
      value={value}
      onChange={(e)=>handleChange(e.target.value)}
      InputProps={{
        endAdornment: <InputAdornment position="end">{saved ? <CheckIcon/> : <CircularProgress size={20}/>}</InputAdornment>,
      }}/>
    </div>
  )
}

export default TextLong;
