import React, { useState, createContext, useContext, useEffect, useCallback } from "react";
import Conf from 'Conf';
import { produce } from 'immer';
import { AuthContext } from "providers/AuthProvider";
import { ModelContext } from "providers/ModelProvider";

export const SettingsContext = createContext({});

const SettingsProvider = ({children})=>{
  const { auth } = useContext(AuthContext);
  const { getCollection } = useContext(ModelContext);
  const [ modele,setModele ] = useState({});
  const [ appState,setAppState ] = useState({
    accordions: {}
  });
  const [ uploaders,setUploaders ] = useState([]);
  const [ uploadProgress,setUploadProgress ] = useState([]);
  const addUploaders=(newUploaders,docId,path,addFile)=>{
    let newUploadersTab=newUploaders.map((uploader, i) => {
      return {addFile,uploader,docId,path}
    });
    console.log('add_uploader',newUploadersTab);
    const tab=[...uploaders,...newUploadersTab];
    setUploaders(tab);
  }
  const removeUpload=useCallback((uploader)=>{
    const pIdx=uploadProgress.findIndex((o)=>{
      return o.uploader===uploader || o.uploader._fingerprint===uploader._fingerprint
    });
    console.log(uploadProgress,pIdx);
    setUploadProgress(produce(uploadProgress,(draft)=>{
      draft.splice(pIdx,1);
    }));
    const uIdx=uploaders.findIndex((o)=>o.uploader===uploader);
    setUploaders((state)=>produce(state,(draft)=>{
      draft.splice(uIdx,1);
    }));
  },[uploaders,uploadProgress]);
  const abortUpload=(uploader)=>{
    uploader.abort(false).then(function () {
      console.log('upload aborted');
    }).catch(function (err) {
      console.log('upload aborted error',err);
    });
    removeUpload(uploader);
  }
  useEffect(()=>{
    if (!auth.token) {
      uploaders.forEach((item, i) => {
        item.uploader.abort(false).then(function () {
          console.log('upload aborted');
        }).catch(function (err) {
          console.log('upload aborted error',err);
        });
        removeUpload(item.uploader)
      });
    } else {
      const startUploader=(item)=>{
        console.log('start_uploader',item);
        const callBack=(item)=>(previousUploads)=>{
            // Found previous uploads so we select the first one.
            if (previousUploads.length) {
                item.uploader.resumeFromPreviousUpload(previousUploads[0])
            }
            // Start the upload
            item.uploader.start()
        };
        item.uploader.findPreviousUploads().then(callBack(item));
      };
      const onErrorCallback=(item)=>(error)=>{
        console.log("Failed because: " + error);
      }
      const onProgressCallback=(item)=>(bytesUploaded, bytesTotal)=>{
        let percentage=(bytesUploaded / bytesTotal * 100).toFixed(2);
        setUploadProgress(p=>produce(p,(draft)=>{
          const progress=draft.find((o)=>o.name===item.uploader.file.name);
          if (progress) progress.percentage=percentage;
        }));
      }
      const onSuccessCallback=(item)=>()=>{
        console.log(item);
        console.log("Download %s from %s", item.uploader.file.name, item.uploader.url,item.uploader.url.replace(Conf.uploadUrl+'storage/files/',''));
        item.addFile({
          name:item.uploader.file.name,
          url:item.uploader.url.replace(Conf.uploadUrl+'storage/files/',''),
          type:item.uploader.file.type,
        });
        removeUpload(item.uploader);
      }
      uploaders.forEach((item, i) => {
        item.uploader.options.headers={
          "Authorization": "Bearer "+auth.token
        };
        item.uploader.options.onError=onErrorCallback(item);
        item.uploader.options.onProgress=onProgressCallback(item);
        item.uploader.options.onSuccess=onSuccessCallback(item);
        if (!item.started) {
          item.started=true;
          startUploader(item);
          setUploadProgress(p=>produce(p,(draft)=>{
            const progress=draft.find((o)=>o.name===item.uploader.file.name);
            if (!progress) draft.push({
              percentage:0,
              id:item.uploader.file.name+'-'+item.uploader.file.size+'-'+item.uploader.file.lastModified,
              name:item.uploader.file.name,
              uploader:item.uploader,
            });
          }));
        }
      });
    }
  },[uploaders,auth.token,setUploadProgress,removeUpload]);
  const settingsCollection=getCollection('settings');
  const settings=settingsCollection.length>0 ? settingsCollection[0] : {};
  // useEffect(()=>console.log('uploaders changed'),[uploaders]);
  // useEffect(()=>console.log('auth.token changed'),[auth.token]);
  // useEffect(()=>console.log('setUploadProgress changed'),[setUploadProgress]);
  // useEffect(()=>console.log('removeUpload changed'),[removeUpload]);
  return (
        <SettingsContext.Provider value={{settings,modele,setModele,uploaders,addUploaders,abortUpload,uploadProgress,Conf,appState,setAppState}}>
            {children}
        </SettingsContext.Provider>
    );
}
export default SettingsProvider;
